import { STORAGE_KEY } from "../constants";

const fetchData = async (url, payload = null, method = "POST", signal) => {
  let options = { method, headers: {}, mode: "cors" };

  if (payload) {
    options.body = JSON.stringify(payload);
    options.headers = { ...options.headers, ...{ Accept: "application/json", "Content-Type": "application/json" } };
  }


  options.credentials = "omit";
  options.cache = "no-cache";
  options.signal = signal;

  const setToken = () => {
    let token = localStorage.getItem(STORAGE_KEY.ACCESSTOKEN);
    if (token) {
      options.headers["Authorization"] = "Bearer " + token;
    }
  };
  setToken();

  const wait = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, 4000));
  };

  let fullUrl = url.toLowerCase().startsWith("http") ? url : `https://${process.env.REACT_APP_API_URL}/${url}`;

  async function refreshToken() {
    window.refreshingToken = true;

    try {
      const response = await fetch(`https://${process.env.REACT_APP_API_URL}/auth/refresh-token`, {
        method: "POST", headers: {}, mode: "cors", credentials: "include", cache: "no-cache"
      });
      const data = await response.json();
      if (data?.data?.accessToken) {
        let token = data.data.accessToken;
        options.headers["Authorization"] = "Bearer " + token;
        localStorage.setItem(STORAGE_KEY.ACCESSTOKEN, token);
        return true;
      } else {
        navigateToLogin();
      }
    } finally {
      window.refreshingToken = false;
    }
    return false;
  }

  const navigateToLogin = () => {
    localStorage.removeItem(STORAGE_KEY.ACCESSTOKEN);
    window.history.pushState({}, "Login", "/auth/login");
    window.location.reload();
  };

  async function doFetch(fullUrl, options, refetching = false) {
    if (window.refreshingToken === true) {
      await wait(2000);
      setToken();
    }
    const response = await fetch(fullUrl, options);
    try {
      if (response.status === 401) {
        if (window.refreshingToken !== true) {
          if (!refetching) {
            if (await refreshToken()) return await doFetch(fullUrl, options, true);
          } else {
            navigateToLogin();
          }
        } else {
          await wait(2000);
          setToken();
          return await doFetch(fullUrl, options, true);
        }
      } else {
        const res = await response.json();

        if (!res) throw new Error("There was an error connecting to the server.");

        if (!response.ok || (res.hasOwnProperty("success") && res.success === false)) {
          throw res.message || "There was an error connecting to the server.";
        }
        return res;
      }
    } catch (err) {
      if (err.name === "AbortError") {
        // handle abort()
      } else {
        throw err;
      }
    }
  }

  return await doFetch(fullUrl, options);
};

export default fetchData;
