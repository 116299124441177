import React from 'react';
import MainChart from '../../charts/MainChart';

const ChartPage = () => {
  return (
    <>
      <MainChart dateStart={'11/1/2023'} dateEndXXX={'3/10/2024'} />
    </>
  );
};

export default ChartPage;
