import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Button from '../ui/buttons/Button';
import fetchData from '../../services/fetchData';
import Icon from '../ui/Icon';

const SendConfirmEmail = () => {
  const { userId } = useParams();
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [token, setToken] = useState(''); //TEMP UNTIL EMAIL WORKS

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    fetchData(`auth/send-confirm-email/${userId}`, null, 'GET')
      .then((res) => setToken(res.data)) ///TEMPE
      .finally(() => {
        setLoading(false);
        setSent(true);
      });
  };

  return (
    <React.Fragment>
      <div className="py-8">
        <div className="text-lg pb-5">Confirm your email</div>
        <div className="flex flex-col gap-3">
          {token && (
            <div>
              <Link className="text-primary-500" to={`/auth/set-password?token=${token}&id=${userId}`}>
                Click this temporary confirmation
              </Link>
            </div>
          )}
          {!token && (
            <div>
              <p>Your email has not been confirmed yet.</p>
              <p>Please check your email and follow the included link to confirm your email, and set your password.</p>
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-end items-center">
        {sent && (
          <div className="text-green-500 font-medium flex items-center">
            <Icon className="mr-2" icon="check" size={20} /> Email Sent
          </div>
        )}
        {!sent && (
          <div>
            {' '}
            <Button loading={loading} loadingText="Sending..." onClick={handleSubmit}>
              Resend Email
            </Button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default SendConfirmEmail;
