//import { ensureDefined } from '../helpers/assertions';

export class PluginBase {
  _chart = undefined;

  get chart() {
    return this._chart;
  }

  _series = undefined;

  get series() {
    return this._series;
  }

  requestUpdate() {
    if (this._requestUpdate) this._requestUpdate();
  }

  attached({ chart, series, requestUpdate }) {
    this._chart = chart;
    this._series = series;
    this._series.subscribeDataChanged(this._fireDataUpdated);
    this._requestUpdate = requestUpdate;
    this.requestUpdate();
  }

  detached() {
    this._series?.unsubscribeDataChanged(this._fireDataUpdated);
    this._chart = undefined;
    this._series = undefined;
    this._requestUpdate = undefined;
  }

  // This method is a class property to maintain the
  // lexical 'this' scope (due to the use of the arrow function)

  // and to ensure its reference stays the same, so we can unsubscribe later.
  _fireDataUpdated = (scope) => {
    if (this.dataUpdated) {
      this.dataUpdated(scope);
    }
  };
}
