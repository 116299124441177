import { createChart } from 'lightweight-charts';
import './plugins/user-pricelines';
import React, {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { UserPriceLines } from './plugins/user-pricelines';
import { SessionHighlighting } from './plugins/session-highlight';
import { CHART_INTERVALS } from '../../constants';
import { isDark } from '../ui/theme/theme';

const Context = createContext();

export function Chart(props) {
  const { toolbar, ...rest } = props;

  const [container, setContainer] = useState(false);
  const handleRef = useCallback(ref => setContainer(ref), []);
  return (
    <div ref={handleRef} className="h-full relative w-full flex flex-col bg-white dark:bg-gray-900">
      {toolbar && <div className="flex-none h-[40px] border-b border-b-gray-100 dark:border-b-gray-800">{toolbar}</div>}
      <div className="grow">
        {container && <ChartContainer {...rest} headerHeight={toolbar ? 40 : 0} container={container} />}
      </div>
    </div>
  );
}

export const ChartContainer = forwardRef((props, ref) => {
  const { children, container, layout, options, ...rest } = props;

  const chartApiRef = useRef({
    api() {
      if (!this._api) {
        this._api = createChart(container, {
          layout,
          ...options,
          width: container.clientWidth,
          height: container.clientHeight - props.headerHeight,
          localization: {
            timeFormatter: time => new Date(time * 1000).toLocaleString(),
          },
        });
        //this._api.timeScale().fitContent();
      }
      return this._api;
    },
    free() {
      if (this._api) {
        this._api.remove();
        this._api = undefined;
      }
    },
  });

  useLayoutEffect(() => {
    const currentRef = chartApiRef.current;
    const chart = currentRef.api();

    const handleResize = () => {
      chart.applyOptions({
        ...rest,
        width: container.clientWidth,
        height: container.clientHeight - props.headerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => {
      try {
        window.removeEventListener('resize', handleResize);
        //if (chart?.remove) chart.remove();
      } catch (err) {}
    };
  }, [rest, container, props.headerHeight]);

  useLayoutEffect(() => {
    const currentRef = chartApiRef.current;
    currentRef.api();
  }, []);

  useLayoutEffect(() => {
    const currentRef = chartApiRef.current;
    currentRef.api().applyOptions(rest);
  }, [rest]);

  useImperativeHandle(ref, () => chartApiRef.current.api(), []);

  useEffect(() => {
    const currentRef = chartApiRef.current;
    currentRef.api().applyOptions({ layout });
  }, [layout]);

  return <Context.Provider value={chartApiRef.current}>{props.children}</Context.Provider>;
});
ChartContainer.displayName = 'ChartContainer';

export const SERIES_TYPE = {
  LINE: 'line',
  AREA: 'area',
  CANDLE: 'candle',
  BAR: 'bar',
  HISTOGRAM: 'hist',
};

export const Series = forwardRef((props, ref) => {
  const parent = useContext(Context);
  const context = useRef({
    api() {
      if (!this._api) {
        const { children, data, type, priceLines, markers, onTimeChange, localLevels, levels, ...rest } = props;
        let series;
        switch (type.toLowerCase()) {
          case SERIES_TYPE.AREA:
            series = parent.api().addAreaSeries(rest);
            break;
          case SERIES_TYPE.CANDLE:
            series = parent.api().addCandlestickSeries(rest);
            break;
          case SERIES_TYPE.BAR:
            series = parent.api().addBarSeries(rest);
            break;
          case SERIES_TYPE.HISTOGRAM:
            series = parent.api().addHistogramSeries(rest);
            break;
          default:
            series = parent.api().addLineSeries(rest);
        }

        if (markers) {
          series.setMarkers(markers);
        }

        this._api = series;
        this._api.setData(data);

        if (props.highlight) {
          const sessionHighlighter = time => {
            const isDateBetween = (date, startHour, startMinutes, endHour, endMinutes) => {
              // Convert the hours and minutes to minutes for easier comparison
              const startMinutesTotal = startHour * 60 + startMinutes;
              const endMinutesTotal = endHour * 60 + endMinutes;
              const dateMinutesTotal = date.getHours() * 60 + date.getMinutes();

              // If the end time is less than the start time, it means the time range spans two days
              if (endMinutesTotal < startMinutesTotal) {
                // If the date is before the start time on the first day or after the end time on the second day, it's not between the times
                return !(dateMinutesTotal < startMinutesTotal && dateMinutesTotal > endMinutesTotal);
              } else {
                // If the end time is greater than the start time, it's a normal range within one day
                return dateMinutesTotal >= startMinutesTotal && dateMinutesTotal <= endMinutesTotal;
              }
            };
            const date = new Date(time * 1000);
            if (props?.period < CHART_INTERVALS.ONE_DAY) {
              if (isDateBetween(date, 8, 30, 14, 59)) return isDark() ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.03)';
              //if (isDateBetween(date, 17, 0, 8, 29)) return isDark() ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.03)';
              if (isDateBetween(date, 15, 0, 16, 59))
                return isDark() ? 'rgba(255, 255, 255, .055)' : 'rgba(0, 0, 0, .06)';
            }
          };
          const sessionHighlight = new SessionHighlighting(sessionHighlighter);
          series.attachPrimitive(sessionHighlight);
        }

        if (props?.userPriceLines) {
          new UserPriceLines(parent?._api, series, { color: 'hotpink' });
          this._api._priceLines = [];
          if (priceLines) {
            priceLines.forEach(l => {
              this._api._priceLines.push(series.createPriceLine(l));
            });
          }
        }
      }
      return this._api;
    },
    free() {
      if (this._api && parent?._api) {
        parent.free();
      }
      this._api = undefined;
    },
  });

  useEffect(() => {
    const currentRef = context?.current;
    if (currentRef?.api) currentRef.api();
    currentRef.api().setData(props.data);
  }, [props.data]);

  useLayoutEffect(() => {
    const currentRef = context?.current;
    if (currentRef?.api) currentRef.api();
    if (currentRef.api()._priceLines) {
      currentRef.api()._priceLines.forEach(l => currentRef.api().removePriceLine(l));
    }
    if (props?.priceLines) {
      props.priceLines.forEach(l => {
        currentRef.api()._priceLines.push(currentRef.api().createPriceLine(l));
      });
    }
  }, [props.priceLines]);

  useLayoutEffect(() => {
    const currentRef = context?.current;
    if (currentRef?.api) currentRef.api();

    return () => currentRef?.free && currentRef.free();
  }, []);

  useLayoutEffect(() => {
    const currentRef = context.current;
    const { children, data, ...rest } = props;
    currentRef.api().applyOptions(rest);
  });

  useImperativeHandle(ref, () => context.current.api(), []);

  return <Context.Provider value={context.current}>{props.children}</Context.Provider>;
});
Series.displayName = 'Series';
