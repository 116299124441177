import React from 'react';

const Header = ({ children, title }) => {
  return (
    <div className="px-6 py-2 flex border-y border-gray-100 dark:border-gray-900 bg-white dark:bg-gray-900 w-full items-center justify-between">
      {title && <div className="flex items-center text-3xl font-thin">{title}</div>}
      {children}
    </div>
  );
};

export default Header;
