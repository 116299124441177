import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Auth from '../auth/Auth';
import Page from '../layout/Page';
import SendConfirmEmail from '../auth/SendConfirmEmail';
import SetPassword from '../auth/SetPassword';
import AuthenticatedRoute from './AuthenticatedRoute';
import Login from '../auth/Login';
import SignUp from '../auth/SignUp';
import ForgotPassword from '../auth/ForgotPassword';
//https://reactrouter.com/en/main/upgrading/v6-data
const RoutesRoot = () => {
  return (
    <Routes>
      <Route
        path="/*"
        element={
          <AuthenticatedRoute>
            <Page />
          </AuthenticatedRoute>
        }
      ></Route>
      <Route path="auth/*" element={<Auth />}>
        <Route path="login" element={<Login />}></Route>
        <Route path="signup" element={<SignUp />}></Route>
        <Route path="send-confirm-email/:userId" element={<SendConfirmEmail />}></Route>
        <Route path="set-password" element={<SetPassword />}></Route>
        <Route path="forgot-password" element={<ForgotPassword />}></Route>
        <Route path="*" element={<Login />}></Route>
      </Route>
      <Route
        path="*"
        element={
          <AuthenticatedRoute>
            <Page />
          </AuthenticatedRoute>
        }
      ></Route>
    </Routes>
  );
};
export default RoutesRoot;
