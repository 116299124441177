import React, { useEffect } from 'react';
import { useSetAtom } from 'jotai';
import jwt from '../../helpers/jwt';
import { systemAtom } from '../../state/systemStore';
import { STORAGE_KEY, USER_SETTINGS } from '../../constants';
import { getQuery } from '../../services/fetchQuery';
import { setPageTheme, setThemeInStorage } from '../ui/theme/theme';

const GlobalData = ({ children }) => {
  const setSystem = useSetAtom(systemAtom);

  useEffect(() => {
    getQuery('usersettings/' + USER_SETTINGS.THEME).then(res => {
      const data = JSON.parse(res);
      setPageTheme(data);
      setThemeInStorage(data);
    });

    try {
      var token = localStorage.getItem(STORAGE_KEY.ACCESSTOKEN);
      if (token) {
        const claims = jwt.parseToken(token);
        setSystem((prev) => ({ ...prev, userId: parseInt(claims.userId) }));
      }
    } catch (err) {
      console.log(err);
    }
  }, [setSystem]);

  return <React.Fragment>{children}</React.Fragment>;
};

export default GlobalData;
//claims
// clientId: "1";
// exp: 1692547997;
// iat: 1689955997;
// nbf: 1689955997;
// salesPermissions: "[1,13,9,2,6,7,8,11,12]";
// salesPlan: "0";
// salesUserId: "1";
// userId: "1";
