import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../ui/buttons/Button';
import PasswordInput from '../ui/forms/PasswordInput';
import TextInput from '../ui/forms/TextInput';
import useServiceMutation from '../../mutations/useServiceMutation';
import apiService from '../../services/apiService';
import { STORAGE_KEY } from '../../constants';

const Login = () => {
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('demo@speculated.io');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [emailErrors, setEmailErrors] = useState([]);
  const [pwErrors, setPwErrors] = useState([]);
  const loginMutation = useServiceMutation(apiService.auth.authenticate);

  let navigate = useNavigate();

  const onClick = e => {
    e.preventDefault();

    setEmailErrors(!email ? ['Your email is required'] : []);
    setPwErrors(!password ? ['Your password is required'] : []);
    if (!email || !password) return;

    setLoading(true);
    const handleResponse = (data, error) => {
      setLoading(false);
      if (!data) return;
      switch (data.result) {
        case 0:
          localStorage.setItem(STORAGE_KEY.ACCESSTOKEN, data.accessToken);
          navigate(`/`);
          break;
        case 3:
          navigate(`/auth/send-confirm-email/${data.userId}`);
          break;
        default:
          setMessage('Invalid email or password');
      }
    };
    setLoading(true);
    loginMutation.mutate({ email, password }, { onSettled: handleResponse });
  };

  return (
    <form>
      <div className="pb-6 text-3xl items-center w-full text-center">Login</div>
      <div className="pb-8 w-full">
        <div className="flex flex-col gap-3">
          <TextInput
            label="Email"
            autoComplete="email"
            required
            autoFocus
            danger={message}
            messages={emailErrors}
            onChange={e => setEmail(e.value)}
            value={email}></TextInput>
          <PasswordInput
            label="Password"
            autoComplete="current-password"
            required
            danger={message}
            messages={pwErrors}
            onChange={e => setPassword(e.value)}
            value={password}></PasswordInput>
          {message && <div className="text-danger-500 pt-2 pl-2">{message}</div>}
          <div className="flex justify-end">
            <Link to={`/auth/forgot-password?email=${encodeURI(email)}`}>
              <Button link>Forgot password?</Button>
            </Link>
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center">
        <div>
          <Link to="/auth/signup">
            <Button link>Create account</Button>
          </Link>
        </div>
        <div>
          <Button type="submit" loading={loading} loadingText="Verifying..." onClick={onClick} className="">
            Login
          </Button>
        </div>
      </div>
    </form>
  );
};
export default Login;
