import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Admin from '../pages/admin/Admin';
import AdminNotFound from '../pages/admin/AdminNotFound';
import UsersAdmin from '../pages/admin/security/users/Users';
import DefaultTheme from '../pages/admin/appearance/DefaultTheme';
import Algos from '../pages/algos/Algos';
import Orders from '../pages/orders/Orders';
import Levels from '../pages/levels/Levels';
import Rules from '../pages/rules/Rules';
import Simulator from '../pages/simulator/Simulator';
import Accounts from '../pages/accounts/Accounts';
import ChatBot from '../pages/chatBot/ChatBot';
import ChartPage from '../pages/chart/ChartPage';

const RoutesPages = () => {
  return (
    <Routes>
      <Route path="/" element={<ChartPage />} />
      <Route path="/chart" element={<ChartPage />} />
      <Route path="/algos" element={<Algos />} />
      {/*<Route path="/training" element={<Training/>}/>*/}
      {/*<Route path="/models" element={<Models/>}/>*/}
      <Route path="/orders" element={<Orders />} />
      <Route path="/levels" element={<Levels />} />
      <Route path="/rules" element={<Rules />} />
      <Route path="/simulator" element={<Simulator />} />
      <Route path="/accounts" element={<Accounts />} />
      <Route path="/chat-bot" element={<ChatBot />} />
      <Route path="/settings/*" element={<Admin />}>
        <Route path="users" element={<UsersAdmin />} />
        <Route path="theme" element={<DefaultTheme />}></Route>
        <Route path="*" element={<AdminNotFound />}></Route>
      </Route>
      <Route path="*" element={<ChartPage />}></Route>
    </Routes>
  );
};

export default RoutesPages;

/*



*/
