import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from '../ui/buttons/Button';
import PasswordInput from '../ui/forms/PasswordInput';
import Icon from '../ui/Icon';
import { useAtom } from 'jotai';
import { atomWithImmer } from 'jotai-immer';
import { STORAGE_KEY } from '../../constants';
import useServiceMutation from '../../mutations/useServiceMutation';
import apiService from '../../services/apiService';

const errorAtom = atomWithImmer({
  whiteSpace: true,
  upperCase: false,
  lowerCase: false,
  number: false,
  symbol: false,
  pwLength: false,
  confirmed: false,
  score: 10,
});

const isContainsWhitespace = /^(?=.*\s)/;
const isContainsUppercase = /^(?=.*[A-Z])/;
const isContainsLowercase = /^(?=.*[a-z])/;
const isContainsNumber = /^(?=.*[0-9])/;
const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹])/;

const SetPassword = () => {
  const [searchParams] = useSearchParams();
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useAtom(errorAtom);
  const [errorSaving, setErrorSaving] = useState('');
  const mutation = useServiceMutation(apiService.auth.setPassword);

  let navigate = useNavigate();

  const handleSave = (e) => {
    e.preventDefault();

    const handleSaved = (data, error) => {
      setLoading(false);
      if (data?.accessToken) {
        localStorage.setItem(STORAGE_KEY.ACCESSTOKEN, data.accessToken);
        navigate(`/`);
      } else {
        setErrorSaving(true);
      }
    };

    setLoading(true);

    const payload = {
      userId: searchParams.get('id'),
      password: password1,
      token: searchParams.get('token'),
    };

    mutation.mutate(payload, { onSettled: handleSaved });
  };

  const testPasswords = (pw1, pw2) => {
    setErrors((d) => {
      d.confirmed = pw2 === pw1;
      d.pwLength = pw1.length > 7;
      d.whiteSpace = isContainsWhitespace.test(pw1);
      d.upperCase = isContainsUppercase.test(pw1);
      d.lowerCase = isContainsLowercase.test(pw1);
      d.number = isContainsNumber.test(pw1);
      d.symbol = isContainsSymbol.test(pw1);
      d.score = calcScore(pw1);
    });
  };
  const handlePassword1 = (e) => {
    testPasswords(e.value, password2);
    setPassword1(e.value);
  };

  const calcScore = (val) => {
    let score = 0;
    if (val.length < 8) score++;
    if (isContainsWhitespace.test(val)) score++;
    if (!isContainsUppercase.test(val)) score++;
    if (!isContainsLowercase.test(val)) score++;
    if (!isContainsNumber.test(val)) score++;
    if (!isContainsSymbol.test(val)) score++;
    return score;
  };

  const handlePassword2 = (e) => {
    setPassword2(e.value);
    testPasswords(password1, e.value);
  };

  const ErrorItem = ({ isError, title }) => (
    <div className="flex items-center">
      <Icon
        icon={isError ? 'circle' : 'check_circle'}
        className={`mr-2 ${isError ? 'text-inherit opacity-50' : 'text-green-500'}`}
        size={18}
      />
      {title}
    </div>
  );

  return (
    <React.Fragment>
      <div className="text-xl items-center w-full text-center">Please enter your new password</div>
      <div className="flex flex-col pt-4 pb-6 text-sm">
        <ErrorItem isError={password1 !== password2 || password2.length < 1} title="Passwords must match." />
        <ErrorItem isError={!errors.pwLength} title="Login must be at least 8 characters." />
        <ErrorItem isError={errors.whiteSpace || password1.length < 1} title="No whitespaces." />
        <ErrorItem isError={!errors.upperCase} title="At least one uppercase character." />
        <ErrorItem isError={!errors.lowerCase} title="At least one lowercase character." />
        <ErrorItem isError={!errors.number} title="At least one number." />
        <ErrorItem isError={!errors.symbol} title="At least one special symbol." />
      </div>

      <div className="pb-4 w-full">
        <div>
          <PasswordInput
            autoComplete="new-password"
            autoFocus
            className="py-3"
            placeholder="Enter your password"
            dangerx={errors.score > 2}
            warnx={errors.score > 0}
            success={errors.score === 0}
            onChange={handlePassword1}
            value={password1}
          ></PasswordInput>
        </div>
      </div>
      <div className="pb-4 w-full">
        <div>
          <PasswordInput
            autoComplete="new-password"
            className="py-3"
            placeholder="Confirm your password"
            dangerx={!errors.confirmed && password2.length > 5}
            success={errors.confirmed}
            onChange={handlePassword2}
            value={password2}
          ></PasswordInput>
        </div>
      </div>
      {errorSaving && (
        <div className="flex items-center gap-2 pb-4 pt-4">
          <Icon className="text-danger-500" icon="error" size={20}></Icon> There was an error saving your password.
        </div>
      )}
      <div className="flex justify-end items-center pt-4">
        <div>
          <Button
            loading={loading}
            disabled={errors.score > 0 || !errors.confirmed}
            loadingText="Saving..."
            className=""
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SetPassword;

//https://www.linkedin.com/pulse/create-strong-password-validation-regex-javascript-mitanshu-kumar/
