import React, { useState } from 'react';
import Page from '../common/Page';
import Header from '../../ui/Header';
import Button from '../../ui/buttons/Button';
import useGetAlgos from '../../../queries/useGetAlgos';
import NewAlgoModal from './NewAlgoModal';
import { useMediaQuery } from 'react-responsive';
import TabContainer, { TabPanel } from '../../ui/tabs/TabContainer';
import Orders from '../common/Orders';
import Simulations from '../common/Simulations';
import Icon from '../../ui/Icon';
import AlgoList from './AlgoList';
import useServiceMutation from '../../../mutations/useServiceMutation';
import ApiService from '../../../services/apiService';
import { QUERY_KEY } from '../../../constants';
import { useSelectedAlgoAtom, useSelectedAlgoTabAtom } from '../../../state/store';

const AgloParams = ({ algo }) => {
  return <div className="h-[1000px]">Algo Parameters</div>;
};

const Algos = () => {
  const { data: algos } = useGetAlgos();
  const [showNew, setShowNew] = useState(false);
  const [selectedAlgo, setSelectedAlgo] = useSelectedAlgoAtom();
  const [selectedTab, setSelectedTab] = useSelectedAlgoTabAtom();
  // eslint-disable-next-line no-unused-vars
  const { algos: data, isAlgosLoading: isLoading } = useGetAlgos();
  const mutation = useServiceMutation(ApiService.algos.update, QUERY_KEY.ALGOS);

  const isLg = useMediaQuery({
    query: '(min-width: 1024px)',
  });

  const handleEnabled = (algo, value) => {
    algo.isEnabled = value;
    mutation.mutate(algo);
  };

  return (
    <Page>
      {(isLg || !selectedAlgo) && (
        <Header title={`Algos${selectedAlgo?.name ? ': ' + selectedAlgo.name : ''}`}>
          <div className="h-full flex items-end justify-end">
            <Button onClick={() => setShowNew(true)}>New Algo</Button>
          </div>
        </Header>
      )}
      <div className="flex w-full h-full overflow-hidden">
        {(isLg || (!isLg && !selectedAlgo)) && (
          <div className="overflow-auto flex flex-col lg:max-w-xs w-full bg-white dark:bg-gray-900 pt-4 pb-6 px-4 gap-3">
            <AlgoList
              algos={algos}
              onEnabled={handleEnabled}
              onChange={a => setSelectedAlgo(a)}
              selectedId={selectedAlgo?.id}
            />
          </div>
        )}
        {((!isLg && selectedAlgo) || isLg) && (
          <div className="grow overflow-hidden h-full">
            <div
              className="lg:hidden px-8 cursor-pointer hover:text-blue-500 flex items-center gap-2"
              onClick={() => setSelectedAlgo(null)}>
              <Icon icon="arrow_back" size={24} /> Back to Algos
            </div>
            <div className="lg:hidden px-8 pt-4 font-semibold text-lg">{selectedAlgo?.name}</div>
            <div className="px-8 py-4 w-full h-full">
              <TabContainer selected={selectedTab} onChange={setSelectedTab} scrollOnMobile={false}>
                <TabPanel title="Properties" value="props">
                  Properties
                </TabPanel>
                <TabPanel title="Orders" value="orders">
                  <Orders />
                </TabPanel>
                <TabPanel title="Simulations" value="sims">
                  <Simulations />
                </TabPanel>
                <TabPanel title="Parameters" value="params">
                  <AgloParams />
                </TabPanel>
              </TabContainer>
            </div>
          </div>
        )}
      </div>
      {showNew && <NewAlgoModal isOpen={showNew} onClose={setShowNew}></NewAlgoModal>}
    </Page>
  );
};

export default Algos;
