import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from '../constants';
import apiService from '../services/apiService';

const fetch = async () => {
  return await apiService.users.getUsers();
};

export default function useGetUsers() {
  const queryKey = [QUERY_KEY.USERS];
  const queryFn = () => fetch();

  return useQuery({
    queryKey,
    queryFn,
    enabled: true,
  });
}
