import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY, STALETIME } from '../constants';
import apiService from '../services/apiService';

export default function useGetChart(symbolId, timePeriod, dateStart, dateEnd) {
  return useQuery({
    queryKey: dateEnd
      ? [QUERY_KEY.CHART, symbolId, timePeriod, dateStart, dateEnd]
      : [QUERY_KEY.CHART, symbolId, timePeriod, dateStart],
    queryFn: async () => await apiService.charts.getCandles({ symbolId, timePeriod, dateStart, dateEnd }),
    enabled: true,
    staleTime: dateEnd ? STALETIME.VERYLONG : 0,
    cacheTime: dateEnd ? STALETIME.VERYLONG : 0,
  });
}
