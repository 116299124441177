import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'jotai';
import React from 'react';
import GlobalBlocker from './components/global/GlobalBlocker';
import RoutesRoot from './components/routes/RoutesRoot';
import GlobalModals from './components/modals/GlobalModals';
import { setPageTheme } from './components/ui/theme/theme';

const queryClient = new QueryClient({ defaultOptions: { queries: { staleTime: 10000 } } });

export default function Root() {
  setPageTheme();
  return (
    <div className="h-full w-full dark:bg-gray-950 bg-gray-50 dark:text-gray-300 text-gray-500 subpixel-antialiased">
      <Provider>
        <QueryClientProvider client={queryClient}>
          <GlobalBlocker>
            <GlobalModals></GlobalModals>
            <RoutesRoot></RoutesRoot>
          </GlobalBlocker>
        </QueryClientProvider>
      </Provider>
    </div>
  );
}
