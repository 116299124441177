import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY, STALETIME } from '../constants';
import apiService from '../services/apiService';

const fetch = async (params) => {
  const res = await apiService.userSettings.getSetting(params);
  try {
    return JSON.parse(res);
  }
  catch (err)
  {
    return  res;
  }
};

export default function useGetUserSetting(key) {
  const queryKey = [QUERY_KEY.USERSETTINGS, key];
  const queryFn = () => fetch({ key });

  return useQuery({
    queryKey,
    queryFn,
    enabled: true,
    staleTime: STALETIME.VERYLONG,
  });
}
