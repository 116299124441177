import React from 'react';
import { CHART_INTERVALS, CHART_LEVELS } from '../../constants';

const timePeriodValues = [
  { name: '1m', value: CHART_INTERVALS.ONE_MINUTE },
  { name: '5m', value: CHART_INTERVALS.FIVE_MINUTES },
  { name: '10m', value: CHART_INTERVALS.TEN_MINUTES },
  { name: '15m', value: CHART_INTERVALS.FIFTEEN_MINUTES },
  { name: '30m', value: CHART_INTERVALS.THIRTY_MINUTES },
  { name: '1h', value: CHART_INTERVALS.ONE_HOUR },
  { name: '1d', value: CHART_INTERVALS.ONE_DAY },
];

const levelValues = [
  { name: 'Mancini', value: CHART_LEVELS.MANCINI },
  // { name: 'Hour Pivots', value: CHART_LEVELS.HOUR_PIVOTS },
  // { name: 'Day Pivots', value: CHART_LEVELS.DAY_PIVOTS },
  // { name: 'Local', value: CHART_LEVELS.LOCAL },
];

const ChartButton = ({ selected, children, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`hover:scale-125 select-none duration-300 ease-in-out transition-all cursor-pointer py-0 px-2 flex items-center h-full ${
        selected ? 'rounded-lg bg-gray-200 dark:bg-gray-700' : ''
      } `}
    >
      {children}
    </div>
  );
};

const ChartToolbar = ({ period, levels, onPeriodChange, onLevelsChange }) => {
  return (
    <div className="flex gap-3 items-center pl-4 h-full">
      <div className="flex items-center gap-3">
        {timePeriodValues.map((tp) => (
          <ChartButton
            key={tp.value}
            onClick={() => onPeriodChange && onPeriodChange(tp.value)}
            selected={tp.value === period}
          >
            {tp.name}
          </ChartButton>
        ))}
      </div>
      <div className="flex flex items-center gap-3 border-l border-l-gray-300 dark:border-l-gray-600 pl-4">
        {levelValues.map((l) => (
          <ChartButton
            key={l.value}
            onClick={() => onLevelsChange && onLevelsChange(l.value)}
            selected={levels[l.value]}
          >
            {l.name}
          </ChartButton>
        ))}
      </div>
    </div>
  );
};

export default ChartToolbar;
