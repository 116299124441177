import React from 'react';

const Divider = ({ children, title, className }) => {
  return (
    <div
      className={`border-b dark:border-b-gray-500 dark:border-opacity-30  border-b-gray-200 w-full ${
        className || 'mb-2 pb-2 text-xl font-thin'
      }`}
    >
      {children}
    </div>
  );
};

export default Divider;
