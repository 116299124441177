export const QUERY_KEY = {
  CHART: 'CHART',
  ALGOS: 'ALGOS',
  TEST_PARAMS: 'TEST_PARAMS',
  ACCOUNTS: 'ACCOUNTS',
  USERSETTINGS: 'USERSETTINGS',
  AUTH: 'AUTH',
  MARKERS: 'MARKERS',
  PRICELINES: 'PRICELINES',
};

export const CHART_INTERVALS = {
  ONE_MINUTE: 1,
  FIVE_MINUTES: 5,
  TEN_MINUTES: 10,
  FIFTEEN_MINUTES: 15,
  THIRTY_MINUTES: 30,
  ONE_HOUR: 60,
  ONE_DAY: 1440,
};

export const CHART_LEVELS = {
  MANCINI: 1,
  DAY_PIVOTS: 2,
  HOUR_PIVOTS: 3,
  LOCAL: 4,
};

export const COLOR_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  DANGER: 'danger',
  WARN: 'warn',
  SUCCESS: 'success',
  BG: 'gray',
};

export const COLOR = {
  COFFEE: 'coffee',
  NEUTRAL: 'neutral',
  STONE: 'stone',
  ZINC: 'zinc',
  SLATE: 'slate',
  METAL: 'moss',
  RED: 'red',
  ORANGE: 'orange',
  AMBER: 'amber',
  YELLOW: 'yellow',
  LIME: 'lime',
  GREEN: 'green',
  EMERALD: 'emerald',
  TEAL: 'teal',
  CYAN: 'cyan',
  SKY: 'sky',
  BLUE: 'blue',
  INDIGO: 'indigo',
  VIOLET: 'violet',
  PURPLE: 'purple',
  FUCHSIA: 'fuchsia',
  PINK: 'pink',
  ROSE: 'rose',
};

export const STALETIME = {
  VERYLONG: 28800000, // 8 hours
  LONG: 3600000, // 1 hour
  MEDIUM: 1800000, // 30 min
  SHORT: 300000, // 5 min
  NONE: 0,
};

export const STORAGE_KEY = {
  ACCESSTOKEN: 'at',
  THEME: 'theme',
  THEMEMODE: 'mode',
  TABS: 'tabs',
};

export const USER_SETTINGS = {
  THEME: 'theme',
  ENTITIES: 'entities',
};
