import React from "react";

const UserModal = ({ user, isNew, isOpen, onClose }) => {
  return (
    <>
        {/*<Modal*/}
        {/*  isOpen={isOpen}*/}
        {/*  onClose={onClose}*/}
        {/*  isNew={isNew}*/}
        {/*  initalData={user}*/}
        {/*  entityId={1}*/}
        {/*/>*/}
    </>
  );
};

export default UserModal;
