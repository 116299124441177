import { useAtom } from "jotai";
import React from "react";
import { alertModalAtom, closeAlert, confirmModalAtom, themeModalAtom } from "../../state/modalStore";
import AlertModal from "./AlertModal";
import ConfirmModal from "./ConfirmModal";
import ThemeModal from "./ThemeModal";

const GlobalModals = () => {
  const [alert] = useAtom(alertModalAtom);
  const [confirm] = useAtom(confirmModalAtom);
  const [theme, setTheme] = useAtom(themeModalAtom);

  return (
    <React.Fragment>
      {alert.isOpen && (
        <AlertModal isOpen={alert.isOpen} icon={alert.icon} iconColor={alert.iconColor} title={alert.title} buttonColor={alert.buttonColor} buttonText={alert.buttonText} onClose={closeAlert}>
          {alert.message}
        </AlertModal>
      )}

      {confirm.isOpen && <ConfirmModal {...confirm}>{confirm.message}</ConfirmModal>}

      {theme && <ThemeModal isOpen={theme} onClose={() => setTheme(false)}></ThemeModal>}

    </React.Fragment>
  );
};
export default GlobalModals;
