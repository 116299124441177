import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY, STALETIME } from '../constants';
import apiService from '../services/apiService';

export default function useGetPriceLines() {
  return useQuery({
    queryKey: [QUERY_KEY.PRICELINES],
    queryFn: async () => await apiService.charts.getPriceLines({}),
    enabled: true,
    staleTime: STALETIME.SHORT,
    cacheTime: STALETIME.SHORT,
  });
}
