import React, { useState } from 'react';
import TextInput from '../ui/forms/TextInput';
import { Link } from 'react-router-dom';
import Button from '../ui/buttons/Button';
import useServiceMutation from '../../mutations/useServiceMutation';
import apiService from '../../services/apiService';
import { QUERY_KEY } from '../../constants';
import EmailInput from '../ui/forms/EmailInput';
import { atomWithImmer } from 'jotai-immer';
import { useAtom } from 'jotai';
import { Form } from '../ui/forms/Form';
import { showErrorMessage } from '../../state/modalStore';

const dataAtom = atomWithImmer({ email: '', name: '' });
const errorAtom = atomWithImmer({ email: [], name: [] });

const SignUp = () => {
  const [data, setData] = useAtom(dataAtom);
  const [errors, setErrors] = useAtom(errorAtom);
  const [hasErrors, setHasErrors] = useState(true);
  const [loading, setLoading] = useState(false);
  const signUpMutation = useServiceMutation(apiService.auth.signUp, QUERY_KEY.AUTH);
  const nameMutation = useServiceMutation(apiService.auth.checkName, QUERY_KEY.AUTH);
  const emailMutation = useServiceMutation(apiService.auth.checkEmail, QUERY_KEY.AUTH);
  const [success, setSuccess] = useState(false);

  const handleSignUp = () => {
    const handleResponse = (data, error, variables, context) => {
      setLoading(false);
      if (error || !data) {
        showErrorMessage('There was an error creating your account.');
        return;
      }
      setSuccess(true);
    };
    setLoading(true);
    signUpMutation.mutate(data, { onSettled: handleResponse });
  };

  const handleChange = (o) => {
    const id = o.id;
    if (id === 'email' && o.value) {
      const handleEmail = (data) => {
        setErrors((d) => {
          d[id] = data ? ['This email address is already taken.'] : [];
        });
      };
      emailMutation.mutate(o.value, { onSettled: handleEmail });
    }
    if (id === 'name' && o.value) {
      const handleName = (data) => {
        setErrors((d) => {
          d[id] = data ? ['This name is already taken.'] : [];
        });
      };
      nameMutation.mutate(o.value, { onSettled: handleName });
    }

    setData((d) => {
      d[id] = o.value;
    });
  };

  return (
    <>
      {success && (
        <div className="flex flex-col gap-4 px-4 py-10 justify-center">
          <div className="flex text-xl">Your account has been created.</div>
          <div className="pt-4">
            Check your email (<span className="font-semibold">{data.email}</span>) to confirm and and set your password.
          </div>
        </div>
      )}
      {!success && (
        <Form onErrorsChange={(cnt) => setHasErrors(cnt > 0)}>
          <div className="pb-8 text-3xl items-center w-full text-center">Sign Up</div>
          <div className="pb-8 w-full">
            <div className="flex flex-col gap-3">
              <TextInput
                label="Display Name"
                id="name"
                required
                autoFocus
                description="Other algo traders will see this name."
                autoComplete="nickname"
                messages={errors.name}
                onChange={handleChange}
                value={data.name}
              ></TextInput>

              <EmailInput
                label="Email"
                id="email"
                required
                description="This is used to login, and to receive notifications."
                autoComplete="email"
                messages={errors.email}
                onChange={handleChange}
                value={data.email}
              ></EmailInput>
            </div>
          </div>

          <div className="flex justify-between items-center">
            <div>
              <Link to="/auth/login">
                <Button link>Aready have an account?</Button>
              </Link>
            </div>
            <div>
              <Button
                type="submit"
                disabled={hasErrors}
                loading={loading}
                loadingText="Verifying..."
                onClick={handleSignUp}
                className=""
              >
                Sign Up
              </Button>
            </div>
          </div>
        </Form>
      )}
    </>
  );
};

export default SignUp;
