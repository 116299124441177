import { atom, useAtom } from 'jotai';
import { atomWithImmer } from 'jotai-immer';
import { STORAGE_KEY } from '../constants';

let tabs = [];
if (localStorage[STORAGE_KEY.TABS]) tabs = JSON.parse(localStorage[STORAGE_KEY.TABS]);
export const tabsAtom = atomWithImmer(tabs);
export const blockerAtom = atom('');
export const adminAtom = atomWithImmer({ selectedObjectTab: 0, selectedObject: {} });

const selectedAlgoAtom = atom(null);
export const useSelectedAlgoAtom = () => useAtom(selectedAlgoAtom);
const selectedAlgoTabAtom = atom('props');
export const useSelectedAlgoTabAtom = () => useAtom(selectedAlgoTabAtom);

//https://immerjs.github.io/immer/update-patterns/
