import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY, STALETIME } from '../constants';
import apiService from '../services/apiService';

export default function useGetAccounts() {
  return useQuery({
    queryKey: [QUERY_KEY.ACCOUNTS],
    queryFn: async () => await apiService.accounts.get(),
    enabled: true,
    staleTime: STALETIME.LONG,
  });
}
