import React, { createContext, useEffect } from 'react';
import { useAtom } from 'jotai';
import { atomWithImmer } from 'jotai-immer';

const FormContext = createContext({}, () => {});
const errorsAtom = atomWithImmer({});
const Form = ({ children, onErrorsChange }) => {
  const [formErrors, setFormErrors] = useAtom(errorsAtom);
  const setErrors = (id, count) => {
    setFormErrors((d) => {
      d[id] = count;
    });
    if (onErrorsChange) {
      let count = 0;
      for (const key in formErrors) {
        count += formErrors[key];
      }
      onErrorsChange(count);
    }
  };
  useEffect(() => {}, []);
  return <FormContext.Provider value={{ formErrors, setErrors }}>{children}</FormContext.Provider>;
};

export { Form, FormContext };
