import React from 'react';
import { Outlet } from 'react-router-dom';
import Logo from '../ui/Logo';

const Auth = () => (
  <div className="h-full overflow-auto dark:bg-gray-950 bg-white lg:bg-gray-200 dark:lg:bg-gray-950">
    <div className="lg:p-14 p-4">
      <div className="flex flex-col items-center justify-center lg:h-full  w-full">
        <div className="flex flex-col w-full lg:shadow-lg dark:shadow-none bg-white dark:bg-gray-950 lg:dark:bg-gray-800 lg:rounded-lg lg:py-8 lg:px-8 py-8 px-6 lg:max-w-md">
          <div className="flex items-center w-full lg:justify-center pb-4">
            <div className="lg:w-32 lg:h-32 h-12 w-12">
              <Logo></Logo>
            </div>
            <div className="lg:hidden pl-4 text-2xl">speculated.io</div>
          </div>
          <div className="hidden lg:flex w-full items-center justify-center text-3xl">speculated.io</div>
          <div className="pt-6 lg:pt-8">
            <Outlet />
          </div>
        </div>
        <div className="flex items-center justify-center gap-6 w-full lg:max-w-md py-6 lg:py-6 text-secondary-500">
          <a className="text-gray-500" href="https://google.com">
            Privacy
          </a>
          <a className="text-gray-500" href="https://google.com">
            Terms
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default Auth;
