import React from 'react';
import Icon from '../ui/Icon';
import Logo from '../ui/Logo';
import ProfileMenu from './ProfileMenu';

const PageHeader = ({ onMenuClick, onTabsClick }) => {
  return (
    <header className="flex flex-col bg-white dark:bg-gray-950  ">
      <div className="flex border-none ">
        <div className="hidden lg:flex flex-none items-center justify-center px-2">
          <div className="flex w-10 h-10 p-2 hover:animate-pulse">
            <Logo className=""></Logo>
          </div>
          <div className="flex items-center text-2xl font-mono dark:text-inherit text-gray-500 pl-2">
            Coonass Trading Co.
          </div>
        </div>
        <div className="lg:hidden flex-none w-14 h-14 items-center justify-center inline-flex ml-2">
          <a href="/#" className="inline-flex lg:hidden" onClick={(e) => onMenuClick(e)}>
            <Icon icon="menu1" size={24} />
          </a>
        </div>
        <div className="grow min-w-0 h-12 ">{/*<PageTabs></PageTabs>*/}</div>
        <div className="flex-none pl-4 items-center justify-center inline-flex relative  border-gray-300 dark:border-opacity-0 ">
          {/*<div className="inline-flex mr-6 lg:hidden">*/}
          {/*  <AddTabMenu renderItem={<Icon icon="add" size={24} />} />*/}
          {/*</div>*/}
          {/*<a href="/#" className="inline-flex mr-6 lg:hidden" onClick={onTabsClick}>*/}
          {/*  <Icon icon="view_list" size={24} />*/}
          {/*</a>*/}
          <div className="inline-flex mr-6">
            <ProfileMenu />
          </div>
        </div>
      </div>
    </header>
  );
};

export default PageHeader;
