import React, { useEffect } from 'react';
import Modal from './Modal';
import ThemePicker from '../ui/theme/ThemePicker';
import { atomWithImmer } from 'jotai-immer';
import { useAtom } from 'jotai';
import useModalSaveCancel from './useModalSaveCancel';
import useServiceMutation from '../../mutations/useServiceMutation';
import apiService from '../../services/apiService';
import { QUERY_KEY, USER_SETTINGS } from '../../constants';
import useGetUserSetting from '../../queries/useGetUserSetting';
import { defaultTheme, setPageTheme, setThemeInStorage } from '../ui/theme/theme';
import PageSpinner from '../ui/PageSpinner';

const themeAtom = atomWithImmer(defaultTheme);

const ThemeModal = ({ isOpen, onClose, title = 'Pick the colors for your theme.' }) => {
  const [theme, setTheme] = useAtom(themeAtom);
  const { data, isLoading } = useGetUserSetting(USER_SETTINGS.THEME);

  const handleSave = (setSaving) => {
    setSaving(true);
    const handleSaved = () => {
      setSaving(false);
      setThemeInStorage(theme);
      onClose && onClose();
    };
    mutation.mutate({ key: USER_SETTINGS.THEME, value: theme }, { onSettled: handleSaved });
  };

  const handlChange = (key, value) => {
    setTheme((d) => {
      d[key] = value;
      setPageTheme(d);
    });
  };

  const handleClose = (v) => {
    if (!v) setPageTheme(data);
    onClose && onClose();
  };

  useEffect(() => {
    if (data) setTheme((d) => data);
    else setTheme((d) => defaultTheme);
  }, [data, setTheme]);

  const { buttons } = useModalSaveCancel(handleSave, handleClose);
  const mutation = useServiceMutation(apiService.userSettings.updateSetting, [
    QUERY_KEY.USERSETTINGS,
    USER_SETTINGS.THEME,
  ]);

  return (
    <Modal isOpen={isOpen} icon="format_paint" title={title} buttons={buttons} onCloseRequest={handleClose}>
      {isLoading && <PageSpinner />}
      {!isLoading && theme && <ThemePicker theme={theme} onChange={handlChange} />}
    </Modal>
  );
};

export default ThemeModal;
