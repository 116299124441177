import React from 'react';
import Icon from '../../ui/Icon';

const AlgoList = ({ algos, onChange, selectedId, onEnabled }) => {
  return (
    <>
      {algos &&
        algos.map((a) => (
          <div
            key={a.id}
            onClick={() => onChange(a)}
            className={`${
              selectedId !== undefined && selectedId === a.id
                ? 'border-primary-500 dark:border-primary-500'
                : 'dark:border-transparent border-gray-100 hover:border-gray-400 dark:hover:border-gray-500 hover:cursor-pointer'
            } px-4 py-4 border  dark:bg-gray-800 rounded-xl duration-300 transition-all`}
          >
            <div className="flex flex-col w-full gap-2 select-none">
              <div className="flex w-full justify-between items-center">
                <div className="flex gap-3 items-center">
                  <div
                    className={`w-6 h-6 ${
                      a.isEnabled ? (a.isBull ? 'text-success-500' : 'text-danger-500') : 'text-inherit'
                    }`}
                  >
                    <Icon icon={a.isBull ? 'bull' : 'bear'} size={24} />
                  </div>
                  <div
                    className={`${selectedId !== undefined && selectedId === a.id && 'text-primary-500'} font-semibold`}
                  >
                    {a.name || 'Unnamed Algo'}
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <div>{a.account}</div>
                <div className={`font-mono px-2 rounded dark:bg-gray-600 bg-gray-200`}>{a.isEs ? 'ES' : 'MES'}</div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default AlgoList;
