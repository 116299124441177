import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY, STALETIME } from "../constants";
import apiService from "../services/apiService";

export default function useGetMarkers() {
  return useQuery({
    queryKey: [QUERY_KEY.MARKERS],
    queryFn: async () => await apiService.charts.getMarkers({}),
    enabled: true,
    staleTime: STALETIME.VERYLONG,
    cacheTime: STALETIME.VERYLONG
  });
}
