import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY, STALETIME } from '../constants';
import apiService from '../services/apiService';

export default function useGetAlgos() {
  return useQuery({
    queryKey: [QUERY_KEY.ALGOS],
    queryFn: async () => await apiService.algos.get(),
    enabled: true,
    staleTime: STALETIME.LONG,
  });
}
