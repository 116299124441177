import React, { useEffect, useState } from 'react';
import Spinner from '../../../../ui/Spinner';
import Header from '../../../../ui/Header';
import SelectableItem from '../../../../ui/SelectableItem';
import Button from '../../../../ui/buttons/Button';
import useGetUsers from '../../../../../queries/useGetUsers';
import UserDetail from './UserDetail';
import UserModal from './UserModal';
import { focusAtom } from 'jotai-optics';
import { adminAtom } from '../../../../../state/store';
import { useAtom } from 'jotai';

const selectedAtom = focusAtom(adminAtom, (o) => o.prop('selectedUser'));

const Users = () => {
  const { data, isLoading } = useGetUsers();
  const [selected, setSelected] = useAtom(selectedAtom);
  const [isNewOpen, setIsNewOpen] = useState(false);

  useEffect(() => {
    setSelected((prev) => {
      if (data?.length > 0) {
        if (!prev) return data[0];
        const user = data.find((u) => u.id === prev.id);
        if (!user) return data[0];
        return user;
      }
      return null;
    });
  }, [data, setSelected]);

  return (
    <div className="h-full">
      <Header>
        <div className="flex">
          <div className="flex grow">Users</div>
          <div className="flex flex-none text-base">
            <Button onClick={() => setIsNewOpen(true)}>Add User</Button>
          </div>
        </div>
      </Header>
      {isLoading && (
        <div className="flex items-center justify-center py-8 h-full">
          <div className="h-20 w-20">
            <Spinner></Spinner>
          </div>
        </div>
      )}
      {!isLoading && data && (
        <div className="flex h-full">
          <div className="flex h-full border-r dark:border-opacity-0 border-gray-300 shadow-lg p-4 overflow-auto">
            <div className="flex flex-col gap-2">
              {data?.length > 0 &&
                data.map((u) => (
                  <SelectableItem selected={selected && selected.id === u.id} onClick={() => setSelected(u)} key={u.id}>
                    {u.lastName}, {u.firstName}
                  </SelectableItem>
                ))}
            </div>
          </div>
          <div className="bg-gray-200 dark:bg-gray-800 grow h-full overflow-auto">
            <div className="flex justify-center py-8 px-8">
              {((data.length > 0 && selected) || data.length < 1) && (
                <UserDetail user={selected} users={data}></UserDetail>
              )}
            </div>
          </div>
          {isNewOpen && <UserModal isOpen={isNewOpen} isNew={true} onClose={() => setIsNewOpen(false)} />}
        </div>
      )}
    </div>
  );
};

export default Users;
