import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY, STALETIME } from '../constants';
import apiService from '../services/apiService';

export default function useGetLevels(dateStart, dateEnd) {
  return useQuery({
    queryKey: dateEnd ? [QUERY_KEY.CHART, dateStart, dateEnd] : [QUERY_KEY.CHART, dateStart],
    queryFn: async () => await apiService.charts.getLevels({ dateStart, dateEnd }),
    enabled: true,
    staleTime: STALETIME.VERYLONG,
  });
}
