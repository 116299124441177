import React from 'react';
import fetchData from '../../services/fetchData';
import { useAtom } from 'jotai';
import { themeModalAtom } from '../../state/modalStore';
import { STORAGE_KEY } from '../../constants';
import { Menu, MenuItem } from '../ui/menu/Menu';
import Icon from '../ui/Icon';

const ProfileMenu = () => {
  const [, setTheme] = useAtom(themeModalAtom);

  const toggleDark = (e) => {
    e.preventDefault();
    if (document.documentElement.classList.contains('dark')) {
      localStorage[STORAGE_KEY.THEMEMODE] = 'light';
    } else {
      localStorage[STORAGE_KEY.THEMEMODE] = 'dark';
    }

    if (
      localStorage[STORAGE_KEY.THEMEMODE] === 'dark' ||
      (!(STORAGE_KEY.THEMEMODE in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
    
  };

  const signOut = (e) => {
    e.preventDefault();
    fetchData(`auth/revoke-token`, null, 'GET').then((res) => {
      localStorage.removeItem(STORAGE_KEY.ACCESSTOKEN);
      window.history.pushState({}, 'Login', '/auth/login');
      window.location.reload();
    });
  };

  return (
    <React.Fragment>
      <Menu
        renderItem={
          <div className="hover:dark:bg-gray-700 dark:bg-gray-600 bg-gray-200 hover:text-white hover:bg-blue-500 rounded-full flex items-center justify-center w-8 h-8 p-1">
            <Icon icon="person" />
          </div>
        }
      >
        <MenuItem label="Toggle Dark Mode" icon="brightness_2" onClick={toggleDark} />
        <MenuItem label="Choose Theme Colors" icon="color_lens" onClick={() => setTheme(true)} />
        <MenuItem label="Sign Out" icon="logout" onClick={signOut} />
      </Menu>
    </React.Fragment>
  );
};

export default ProfileMenu;
