import React, { useEffect, useState } from 'react';
import Modal from '../../modals/Modal';
import { atomWithImmer } from 'jotai-immer';
import { useAtom } from 'jotai';
import useModalSaveCancel from '../../modals/useModalSaveCancel';
import { Form } from '../../ui/forms/Form';
import TextInput from '../../ui/forms/TextInput';
import Select from '../../ui/forms/Select';
import Radio, { RadioGroup } from '../../ui/forms/Radio';
import DateInput from '../../ui/forms/DateInput';
import NumberInput from '../../ui/forms/NumberInput';
import useServiceMutation from '../../../mutations/useServiceMutation';
import apiService from '../../../services/apiService';
import { QUERY_KEY } from '../../../constants';
import { algoTypeProps } from '../../../enums';
import useGetAccounts from '../../../queries/useGetAccounts';
import TabContainer, { TabPanel } from '../../ui/tabs/TabContainer';

const defEnd = new Date();
defEnd.setHours(0, 0, 0, 0);
const defStart = new Date(defEnd);
defStart.setMonth(defEnd.getMonth() - 1);

const INITIAL_VALUE = {
  name: 'xxx',
  algoType: 0,
  exitType: 0,
  accountId: '',
  isEs: false,
  tuneStart: defStart,
  tuneEnd: defEnd,
  entryParams: {},
  exitParams: {},
  testParams: {},
  target1Contracts: 3,
  target2Contracts: 1,
  runnerContracts: 1,
};

const dataAtom = atomWithImmer(INITIAL_VALUE);
const errorAtom = atomWithImmer({});
const NewAlgoModal = ({ isOpen, onClose }) => {
  const [data, setData] = useAtom(dataAtom);
  // eslint-disable-next-line no-unused-vars
  const [errors, setErrors] = useAtom(errorAtom);
  // eslint-disable-next-line no-unused-vars
  const [hasErrors, setHasErrors] = useState(true);
  const { data: accounts } = useGetAccounts();
  const algoMutation = useServiceMutation(apiService.algos.insert, QUERY_KEY.ALGOS);
  const [selectedTab, setSelectedTab] = useState('props');

  const handleSave = setSaving => {
    setSaving(true);
    const handleSaved = () => {
      setSaving(false);
      onClose && onClose();
      resetState();
    };
    algoMutation.mutate(data, { onSuccess: handleSaved, onError: () => setSaving(false) });
  };

  useEffect(() => {
    setData(d => INITIAL_VALUE);
  }, [setData]);

  const resetState = () => {
    setData(d => {});
  };

  const handleCancel = () => {
    resetState();
    onClose && onClose(false);
  };

  const handleChange = e => {
    console.log(e.id, e.value);
    setData(d => {
      //console.log(e.id, d[e.id]);
      d[e.id] = e.value;
    });
  };

  const { buttons } = useModalSaveCancel(handleSave, handleCancel);

  return (
    <Modal
      isOpen={isOpen}
      icon="auto_awesome"
      iconColor="primary"
      title={'Tune a New Algo'}
      buttons={buttons}
      onCloseRequest={handleCancel}>
      <Form onErrorsChange={cnt => setHasErrors(cnt > 0)}>
        <TabContainer scrollOnMobile={true} autoScroll={false} onChange={v => setSelectedTab(v)} selected={selectedTab}>
          <TabPanel value="props" title="Properties">
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-3 py-4 px-2">
              <Select id="algoType" label="Aglo Type">
                {Array.from(algoTypeProps).map(([k, v]) => (
                  <option key={k} value={v}>
                    {v.name}
                  </option>
                ))}
              </Select>
              <Select
                id="accountId"
                description="This is the account the algo will execute your orders on."
                label="Account"
                value={data.accountId}
                onChange={handleChange}>
                <option className="hidden" />
                {accounts &&
                  accounts.map(a => (
                    <option key={a.id} value={a.id}>
                      {a.name}
                    </option>
                  ))}
              </Select>
              <div className="lg:col-span-2">
                <TextInput
                  id="name"
                  description="Name your algo with a descriptive name."
                  messages={errors.name}
                  onChange={handleChange}
                  value={data.name}
                  label="Name"></TextInput>
              </div>
              <div className="flex gap-3">
                <NumberInput
                  label="Target 1 Contracts"
                  min={0}
                  minMessage="Anything below 0 just doesn't make any sense.  Are you sure you know what you are doing?"
                  max={500}
                  isDecimal={false}
                  maxMessage="You are fucking crazy! 500 should be your limit."
                  id="target1Contracts"
                  messages={errors.target1Contracts}
                  onChange={handleChange}
                  value={data.target1Contracts}
                  description="The total number of contracts the algo will use for target 1."></NumberInput>
                <NumberInput
                  label="Target 2 Contracts"
                  min={0}
                  minMessage="Anything below 0 just doesn't make any sense.  Are you sure you know what you are doing?"
                  max={500}
                  isDecimal={false}
                  maxMessage="You are fucking crazy! 500 should be your limit."
                  id="target2Contracts"
                  messages={errors.target2Contracts}
                  onChange={handleChange}
                  value={data.target2Contracts}
                  description="The total number of contracts the algo will use for target 2."></NumberInput>
              </div>
              <div className="flex gap-3">
                <NumberInput
                  label="Runner Contracts"
                  min={0}
                  minMessage="Anything below 0 just doesn't make any sense.  Are you sure you know what you are doing?"
                  max={500}
                  isDecimal={false}
                  maxMessage="You are fucking crazy! 500 should be your limit."
                  id="runnerContracts"
                  messages={errors.runnerContracts}
                  onChange={handleChange}
                  value={data.runnerContracts}
                  description="The total number of contracts the algo will use for you runners (no limit order will be placed)."></NumberInput>
                <RadioGroup
                  id="isEs"
                  onChange={e => handleChange({ id: e.id, value: e.value === 'es' })}
                  description="If you don't know what this is, sign out and fuck off."
                  label="Contract Type"
                  groupName="isEs">
                  <Radio groupName="isEs" value="es" checked={data?.isEs}>
                    ES
                  </Radio>
                  <Radio groupName="isEs" value="mes" checked={!data?.isEs}>
                    MES
                  </Radio>
                </RadioGroup>
              </div>
              <DateInput
                id="tuneStart"
                value={data.tuneStart}
                onChange={handleChange}
                label="Tuning Start Date"
                description={
                  <div className="flex flex-col gap-4">
                    <p>
                      Tuning your algorithm runs a simulation with every entry and exit parameter specified in the test.
                    </p>
                    <p>
                      It will set the parameters to the values that make the most money between the date start and date
                      end.
                    </p>
                  </div>
                }></DateInput>
              <DateInput
                id="tuneEnd"
                value={data.tuneEnd}
                onChange={handleChange}
                label="Tuning End Date"
                description={
                  <div className="flex flex-col gap-4">
                    <p>
                      Tuning your algorithm runs a simulation with every entry and exit parameter specified in the test.
                    </p>
                    <p>
                      It will set the parameters to the values that make the most money between the date start and date
                      end.
                    </p>
                  </div>
                }></DateInput>
            </div>
          </TabPanel>
          <TabPanel value="entry" title="Entry Parameters"></TabPanel>
          <TabPanel value="exit" title="Exit Parameters"></TabPanel>
        </TabContainer>
      </Form>
    </Modal>
  );
};

export default NewAlgoModal;
