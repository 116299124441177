const enums = {
  toText: (type, value) => {
    for (const key in type) {
      if (type[key] === value) return key.replace(/([A-Z])/g, ' $1').trim();
    }
  },

  algoTypes: {
    FailedBreakDown: 0,
    FailedBreakOut: 1,
  },

  exitTypes: {
    Explicit: 0,
    Mancini: 1,
    Pivots: 2,
  },
};

export const algoTypeProps = new Map([
  [enums.algoTypes.FailedBreakDown, { name: 'Failed Breakdown' }],
  [enums.algoTypes.FailedBreakOut, { name: 'Failed Breakout' }],
]);

export const exitTypeProps = new Map([
  [enums.exitTypes.Explicit, { name: 'Explicit Values' }],
  [enums.exitTypes.Mancini, { name: 'Mancini Levels' }],
  [enums.exitTypes.Pivots, { name: 'Pivot Levels' }],
]);

export default enums;
