import fetchQuery, { getQuery } from './fetchQuery';

const apiServiceEndpoints = {
  auth: {
    authenticate: () => `auth/authenticate`,
    signup: () => `auth/signup`,
    refreshToken: () => `auth/refresh-token`,
    revokeToken: () => `auth/revoke-token`,
    confirm2fa: () => `auth/confirm-2fa`,
    resend2fa: () => `auth/resend-2fa`,
    confirmEmail: () => `auth/confirm-email`,
    setPassword: () => `auth/set-password`,
    changePassword: () => `auth/change-password`,
    sendConfirmEmail: params => `auth/send-confirm-email/${params.userId}`,
    sendPasswordReset: () => `auth/send-password-reset`,
    checkName: params => `auth/check-name?name=${params}`,
    checkEmail: params => `auth/check-email?email=${params}`,
  },
  algos: {
    get: () => `algos/`,
    getTestParams: () => `algos/params`,
    insert: () => `algos/`,
    update: () => `algos/`,
  },
  accounts: {
    get: () => `accounts/`,
    insert: () => `accounts/`,
  },
  charts: {
    getCandles: params =>
      `chart/candles?symbolId=${params.symbolId}&timePeriod=${params.timePeriod}&dateStart=${params.dateStart}${
        params.dateEnd ? '&dateEnd=' + params.dateEnd : ''
      }`,
    getLevels: params =>
      `chart/levels?dateStart=${params.dateStart}${params.dateEnd ? '&dateEnd=' + params.dateEnd : ''}`,
    getMarkers: params => `chart/markers`,
    getPriceLines: params => `chart/pricelines`,
  },
  users: {
    updateAgentPolicy: params => `users/${params.agentId}/policy/${params.policyId}`,
    updateAgentRole: params => `users/${params.agentId}/role/${params.roleId}`,
    getAgents: () => `users`,
    getUsers: () => `users`,
    getUser: params => `users/${params.id}`,
    insertUser: () => `users`,
    updateUser: params => `users/${params.id}`,
    deleteUser: params => `users/${params.id}`,
  },
  recentItems: {
    insertRecentItem: () => `recentitems`,
  },
  userSettings: {
    getSetting: params => `usersettings/${params.key}`,
    updateSetting: params => `usersettings`,
  },
  clientSettings: {
    getSetting: params => `clientsettings/${params.key}`,
    updateSetting: params => `clientsettings`,
  },
};

const apiService = {
  auth: {
    signUp: params => fetchQuery(apiServiceEndpoints.auth.signup(), params),
    authenticate: params => fetchQuery(apiServiceEndpoints.auth.authenticate(), params),
    sendPasswordReset: params => fetchQuery(apiServiceEndpoints.auth.sendPasswordReset(), params),
    refreshToken: params => fetchQuery(apiServiceEndpoints.auth.refreshToken(), params),
    revokeToken: params => fetchQuery(apiServiceEndpoints.auth.revokeToken(), params),
    confirm2fa: params => fetchQuery(apiServiceEndpoints.auth.confirm2fa(), params),
    resend2fa: params => fetchQuery(apiServiceEndpoints.auth.resend2fa(), params),
    sendConfirmEmail: params => fetchQuery(apiServiceEndpoints.auth.sendConfirmEmail(), params),
    setPassword: params => fetchQuery(apiServiceEndpoints.auth.setPassword(), params),
    changePassword: params => fetchQuery(apiServiceEndpoints.auth.changePassword(), params),
    checkName: params => getQuery(apiServiceEndpoints.auth.checkName(params)),
    checkEmail: params => getQuery(apiServiceEndpoints.auth.checkEmail(params)),
  },
  accounts: {
    get: () => getQuery(apiServiceEndpoints.accounts.get()),
    insert: params => fetchQuery(apiServiceEndpoints.accounts.insert(), params),
  },
  algos: {
    get: () => getQuery(apiServiceEndpoints.algos.get()),
    insert: params => fetchQuery(apiServiceEndpoints.algos.insert(), params),
    update: params => fetchQuery(apiServiceEndpoints.algos.update(), params, 'PUT'),
    getTestParams: () => getQuery(apiServiceEndpoints.algos.getTestParams()),
  },
  charts: {
    getCandles: params => getQuery(apiServiceEndpoints.charts.getCandles(params)),
    getMarkers: params => getQuery(apiServiceEndpoints.charts.getMarkers(params)),
    getLevels: params => getQuery(apiServiceEndpoints.charts.getLevels(params)),
    getPriceLines: params => getQuery(apiServiceEndpoints.charts.getPriceLines(params)),
  },
  users: {
    getAgents: () => getQuery(apiServiceEndpoints.agents.getAgents()),
    updateAgentRole: params => fetchQuery(apiServiceEndpoints.agents.updateAgentRole(params), null, 'PATCH'),
    updateAgentPolicy: params => fetchQuery(apiServiceEndpoints.agents.updateAgentPolicy(params), null, 'PATCH'),
    getUsers: () => getQuery(apiServiceEndpoints.users.getUsers()),
    getUser: params => getQuery(apiServiceEndpoints.users.getUser(params)),
    insertUser: params => fetchQuery(apiServiceEndpoints.users.insertUser(), params, 'POST'),
    updateUser: params => fetchQuery(apiServiceEndpoints.users.updateUser(params), params, 'PUT'),
    deleteUser: params => fetchQuery(apiServiceEndpoints.users.deleteUser(params), null, 'DELETE'),
  },
  recentItems: {
    insertRecentItem: params => fetchQuery(apiServiceEndpoints.recentItems.insertRecentItem(), params, 'POST'),
  },
  userSettings: {
    getSetting: params => getQuery(apiServiceEndpoints.userSettings.getSetting(params)),
    updateSetting: params => fetchQuery(apiServiceEndpoints.userSettings.updateSetting(params), params, 'PUT'),
  },
  clientSettings: {
    getSetting: params => getQuery(apiServiceEndpoints.clientSettings.getSetting(params)),
    updateSetting: params => fetchQuery(apiServiceEndpoints.clientSettings.updateSetting(params), params, 'PUT'),
  },
};

export default apiService;
