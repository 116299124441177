import React from "react"

const Rules = () => {
    return (
        <>
            <div className="flex w-full h-full justify-center items-center">
                <p className="p-8 dark:bg-gray-800 rounded-3xl animate-pulse">This is not implemented yet</p>
            </div>

        </>
    )
};

export default Rules;