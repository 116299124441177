import React, { useEffect, useRef, useState } from 'react';
import { Chart, Series, SERIES_TYPE } from './Chart';
import colors from '../ui/theme/colors';
import { hslToHex, toHSLObject } from '../ui/theme/colorUtils';
import useGetChart from '../../queries/useGetChart';
import { CHART_INTERVALS } from '../../constants';
import { getTheme, isDark } from '../ui/theme/theme';
import { CrosshairMode, LineStyle, TickMarkType } from 'lightweight-charts';
import ChartToolbar from './ChartToolbar';
import PageSpinner from '../ui/PageSpinner';
import { atomWithImmer } from 'jotai-immer';
import { useAtom } from 'jotai';
import useGetLevels from '../../queries/useGetLevels';
import useGetMarkers from '../../queries/useGetMarkers';
import useGetPriceLines from '../../queries/useGetPriceLines';

const levelsAtom = atomWithImmer({});

const getThemeColor = clr => hslToHex(toHSLObject(colors[getTheme()[clr]]['500']));
const MainChart = props => {
  const series1 = useRef(null);
  const [selectedLevels, setSelectedLevels] = useAtom(levelsAtom);
  const [period, setPeriod] = useState(CHART_INTERVALS.FIFTEEN_MINUTES);
  const [chartLayoutOptions, setChartLayoutOptions] = useState({});
  const getDefaultStartDate = () => {
    const d = new Date();
    switch (period) {
      case CHART_INTERVALS.ONE_MINUTE:
        d.setMonth(d.getMonth() - 2);
        break;
      case CHART_INTERVALS.FIVE_MINUTES:
        d.setMonth(d.getMonth() - 6);
        break;
      case CHART_INTERVALS.TEN_MINUTES:
        d.setMonth(d.getMonth() - 6);
        break;
      case CHART_INTERVALS.FIFTEEN_MINUTES:
        d.setMonth(d.getMonth() - 18);
        break;
      case CHART_INTERVALS.THIRTY_MINUTES:
        d.setMonth(d.getMonth() - 18);
        break;
      case CHART_INTERVALS.ONE_HOUR:
        d.setMonth(d.getMonth() - 24);
        break;
      case CHART_INTERVALS.ONE_DAY:
        d.setMonth(d.getMonth() - 108);
        break;
      default:
        d.setMonth(d.getMonth() - 1);
    }
    return d.toISOString().split('T')[0];
  };

  const { data: candles, isLoading: isCandlesLoading } = useGetChart(
    1,
    period,
    props?.dateStart || getDefaultStartDate(),
    props?.dateEnd,
  );

  const { data: markers } = useGetMarkers();

  const { data: priceSeries } = useGetPriceLines();

  const { data: levels } = useGetLevels('1/1/2021', '1/1/2025');
  const getColor = (name, shade = 500) => hslToHex(toHSLObject(colors[name][shade.toString()]));
  const {
    colors: {
      backgroundColor = hslToHex(toHSLObject(isDark() ? colors[getTheme().bg]['950'] : 'hsl(0, 100, 100)')),
      lineColor = hslToHex(toHSLObject(isDark() ? colors[getTheme().bg]['800'] : colors[getTheme().bg]['100'])),
      textColor = hslToHex(toHSLObject(isDark() ? colors[getTheme().bg]['400'] : colors[getTheme().bg]['500'])),
      upColor = getThemeColor('success'),
      downColor = getThemeColor('danger'),
    } = {},
  } = props;

  //https://tradingview.github.io/lightweight-charts/docs/api/interfaces/TimeChartOptions
  const gridOptions = {
    grid: {
      vertLines: { visible: true, color: lineColor },
      horzLines: { visible: true, color: lineColor },
    },
    rightPriceScale: { borderColor: lineColor },
    leftPriceScale: { borderColor: lineColor },
    timeScale: {
      visible: true,
      timeVisible: true,
      secondsVisible: true,
      borderColor: lineColor,
      tickMarkFormatter: (time, tickMarkType, locale) => {
        const date = new Date(time * 1000);
        switch (tickMarkType) {
          case TickMarkType.Year:
            return date.getFullYear();
          case TickMarkType.Month:
            return new Intl.DateTimeFormat(locale, { month: 'short' }).format(date);
          case TickMarkType.DayOfMonth:
            return date.getDate();
          case TickMarkType.Time:
            return new Intl.DateTimeFormat(locale, { hour: 'numeric', minute: 'numeric' }).format(date);
          case TickMarkType.TimeWithSeconds:
            return new Intl.DateTimeFormat(locale, {
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
            }).format(date);
          default:
            return tickMarkType;
        }
      },
    },

    crosshair: {
      mode: CrosshairMode.Normal,
      vertLine: {
        labelBackgroundColor: hslToHex(toHSLObject(colors[getTheme().primary]['500'])),
      },
      horzLine: {
        labelBackgroundColor: hslToHex(toHSLObject(colors[getTheme().primary]['500'])),
      },
    },
  };

  useEffect(() => {
    setChartLayoutOptions({
      background: {
        color: backgroundColor,
      },
      textColor,
    });
  }, [backgroundColor, textColor]);

  const transformLevels = () => {
    if (!levels) return [];
    const result = levels
      //.filter((l) => l.levelSetId === 0 && selectedLevels["1"])
      .filter(l => l.levelSetId === 2)
      .map(l => {
        return {
          price: l.price,
          color: hslToHex(toHSLObject(colors.amber['500'])),
          lineWidth: l.isMajor ? 1 : 1,
          lineStyle: l.isMajor ? LineStyle.Solid : LineStyle.Dotted,
          axisLabelVisible: true, //title: new Date(l.timeStamp * 1000).toLocaleString(),
          draggable: true,
        };
      });
    return result;
  };

  const transformMarkers = () => {
    if (!markers) return [];
    return markers.map(s => {
      return {
        time: s.timeStamp,
        position: s.position,
        color: hslToHex(toHSLObject(colors[s.color]['500'])),
        shape: s.shape,
        text: s.label,
        price: s.price,
      };
    });
  };

  const getLineOptions = series => {
    let options = {
      lastValueVisible: true,
      visible: true,
      priceLineVisible: false,
      priceLineSource: 1, //0 or 1
      priceLineWidth: 1,
      priceLineColor: '',
      priceLineStyle: 4, //0-4
      color: getColor('blue'),
      lineStyle: 2, //0-4
      lineWidth: 1,
      lineType: 1, //0-2
      lineVisible: true,
      pointMarkersVisible: false,
      pointMarkersRadius: 1,
      crosshairMarkerVisible: true,
      crosshairMarkerRadius: 4,
      crosshairMarkerBorderColor: '',
      crosshairMarkerBackgroundColor: '',
      crosshairMarkerBorderWidth: 2,
      lastPriceAnimation: 1, //0-2
      title: series.name,
    };

    if (series.name.startsWith('session_low_')) {
      let match = series.name.match(/\d+$/);
      return {
        ...options,
        title: `Previous Session Low ${parseInt(match[0])}`,
        color: getColor('purple', 500),
      };
    }
    switch (series.name) {
      case 'running_low':
        return {
          ...options,
          title: 'Recent Low',
          color: getColor('pink', 500),
        };
      case 'open_low':
        return {
          ...options,
          title: 'Opening Low',
          color: getColor('red', 500),
        };
      case 'previous_cash_low':
        return {
          ...options,
          title: 'Previous Cash Low',
          color: getColor('orange', 500),
        };
      case 'overnight_low':
        return {
          ...options,
          title: 'Overnight Low',
          color: getColor('purple', 500),
        };
      default:
        return options;
    }
  };

  return (
    <div className="bg-white dark:bg-gray-900 w-full h-full  text-center">
      {isCandlesLoading && <PageSpinner />}
      {!isCandlesLoading && candles && chartLayoutOptions.background && (
        <Chart
          layout={chartLayoutOptions}
          options={gridOptions}
          toolbar={
            <ChartToolbar
              levels={selectedLevels}
              period={period}
              onLevelsChange={lvl =>
                setSelectedLevels(d => {
                  d[lvl] = !d[lvl];
                })
              }
              onPeriodChange={setPeriod}
            />
          }>
          <Series
            ref={series1}
            priceLines={transformLevels()}
            markers={transformMarkers()}
            type={SERIES_TYPE.CANDLE}
            data={candles}
            highlight={true}
            userPriceLines={true}
            period={period}
            upColor={upColor}
            downColor={downColor}
            borderDownColor={downColor}
            borderUpColor={upColor}
            wickDownColor={downColor}
            wickUpColor={upColor}
          />
          {priceSeries &&
            priceSeries.map(s => (
              <Series
                type={SERIES_TYPE.LINE}
                {...getLineOptions(s)}
                data={s.data}
                period={CHART_INTERVALS.ONE_MINUTE}
              />
            ))}
        </Chart>
      )}
    </div>
  );
};

export default MainChart;
